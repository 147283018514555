import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@popmenu/common-ui';
import { compose } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { createEvent } from '~/utils/eventable';
import { sortByKey } from '../../utils/arrays';
import { withIntl } from '../../utils/withIntl';
import { withSnackbar } from '../../utils/withSnackbar';
import { currentUserShape, withCurrentUser } from '../CurrentSessionProvider';
import { notBlankValidator } from '../../utils/forms';
import updateUserMutation from '../../libs/gql/mutations/users/updateUserMutation.gql';

import BasicForm, {
  SubmitGroup,
} from '../../admin/shared/forms/BasicForm';
import SelectGroup from '../../admin/shared/forms/SelectGroup';

const FavoriteLocationForm = ({ classes, closeForm, currentUser, restaurant, locations, showSnackbar, t, width }) => {
  const isMobile = isWidthDown('sm', width);
  const displayName = currentUser?.displayName;

  return (
    <BasicForm
      mutate={{
        mutation: updateUserMutation,
        onCompleted: () => {
          showSnackbar('Profile updated!');
          createEvent({
            eventableType: 'GuestProfile',
            eventLabel: 'profile | my_info | update_button | update',
            eventType: 'profile_my_info_update_click',
          });
          closeForm();
        },
        toVariables: variables => ({
          userId: currentUser.id,
          userInput: {
            favoriteLocationId: variables.favoriteLocationId,
            restaurantId: restaurant.id,
          },
        }),
      }}
    >
      <React.Fragment>
        <Box
          paddingTop={1}
          paddingBottom={3}
        >
          <Typography className={classes.header}>
            <FormattedMessage
              id="sessions.sign_in_v2_verified_subtitle"
              defaultMessage="Welcome{firstName}!"
              values={{
                firstName: displayName ? ` ${displayName}` : '',
              }}
            />
          </Typography>
          <Box marginY={1}>
            <Typography variant="body1" className={classes.description}>
              <FormattedMessage
                id="models.follower.favorite_location.welcome"
                defaultMessage={"You're logged into {restaurant}. Please tell us your favorite location."}
                values={{
                  restaurant: restaurant.name,
                }}
              />
            </Typography>
          </Box>
          <Box>
            <SelectGroup
              field="favoriteLocationId"
              options={sortByKey(locations.map(option => ({
                label: option.name,
                value: option.id,
              })), 'label')}
              title={t('models.follower.favorite_location.name')}
              validate={notBlankValidator}
            />
          </Box>
        </Box>
        <Box>
          <SubmitGroup
            color="primary"
            justify={!isMobile && 'left'}
            title={t('models.user.update')}
            block={!!isMobile}
            data-cy="update_favorite_button"
          />
        </Box>
      </React.Fragment>
    </BasicForm>
  );
};

FavoriteLocationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  closeForm: PropTypes.func.isRequired,
  currentUser: currentUserShape.isRequired,
  locations: PropTypes.object.isRequired,
  restaurant: PropTypes.object.isRequired,
};

export default compose(
  withIntl,
  withCurrentUser,
  withSnackbar,
)(withWidth()(FavoriteLocationForm));
