export default theme => ({
  description: {
    marginBottom: theme.spacing(3),
  },

  header: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
  },
});
