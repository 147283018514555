import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { compose } from '@shakacode/recompose';

import BasicModal from '../../admin/shared/BasicModal';
import { closeFavoriteLocationModal } from '../ModalActions';
import { withRestaurant } from '../../utils/withRestaurant';
import FavoriteLocationForm from '../sessions/FavoriteLocationForm';
import Loading from '../Loading';
import Query from '../Query';
import locationsQuery from '../../libs/gql/queries/locations/restaurantWithLocationsQuery.gql';
import { makeStyles } from '../../utils/withStyles';
import styles from './styles';

const useStyles = makeStyles(styles);

const FavoriteLocationModal = ({ restaurant }) => {
  const classes = useStyles();
  const showFavoriteLocationModal = useSelector(state => state.modals.showFavoriteLocationModal);
  const dispatch = useDispatch();
  const closeForm = () => {
    dispatch(closeFavoriteLocationModal());
  };

  if (!restaurant || !showFavoriteLocationModal) {
    return null;
  }
  return (
    <BasicModal
      closeButton={false}
      show={showFavoriteLocationModal}
      size="sm"
    >
      <Query
        query={locationsQuery}
        variables={{
          restaurantId: restaurant.id,
        }}
      >
        {({ data, loading }) => {
          if (loading || !data?.restaurant?.locations) {
            return <Loading size="lg" />;
          }

          return (
            <FavoriteLocationForm
              classes={classes}
              closeForm={closeForm}
              restaurant={restaurant}
              locations={data.restaurant.locations}
            />
          );
        }}
      </Query>
    </BasicModal>
  );
};

FavoriteLocationModal.propTypes = {
  restaurant: PropTypes.object.isRequired,
};

export default compose(
  withRestaurant,
)(FavoriteLocationModal);
